import { useState } from "react"

export default () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const toggle = ({ onClose = () => {} } = {}) => {
    if (isModalOpen) {
      onClose()
    }

    setIsModalOpen(!isModalOpen)
  }

  return [isModalOpen, setIsModalOpen, toggle]
}
