import React from "react"
import Modal from "react-responsive-modal"
import PropTypes from "prop-types"

const LayoutModal = ({ isOpen = false, toggle, children, width = 500 }) => {
  return (
    <Modal
      open={isOpen}
      onClose={toggle}
      styles={{
        modal: {
          maxWidth: "unset",
          width: width,
          padding: "unset",
          borderRadius: 8,
          marginTop: 20,
        },
        overlay: {
          background: "rgba(0, 0, 0, 0.5)",
        },
        closeButton: {
          outline: "none",
          position: "absolute",
          top: -40,
          right: -10,
        },
      }}
      center
      closeIconSize={40}
    >
      {children}
    </Modal>
  )
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  children: PropTypes.element.isRequired,
}

export default LayoutModal
