/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Container from "./container"
import { Flex, Box } from "rebass"

const Layout = ({ children }) => {
  return (
    <Flex
      sx={{
        flexDirection: "column",
        minHeight: "100vh",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          width: "100%",
          px: [1, 2, 3],
          pb: [3, 4],
        }}
      >
        <Container px={[0]} pl={[0]} pr={[0]}>
          <Header />
          {children}
        </Container>
      </Box>
    </Flex>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
