import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Flex, Image, Text, Link as RebassLink } from "rebass"
import LogoImg from "../../../images/logo.png"
import { FaInfoCircle } from "react-icons/fa"
import useModal from "../../../hooks/modal"
import LayoutModal from "../modal"
import SiteDescription from "../../site-description"

const Header = () => {
  const [isModalOpen, setIsModalOpen, toggle] = useModal()
  return (
    <Flex
      width="100%"
      px={[2, 3, 4]}
      py={[1, 2, 3]}
      mt={[3]}
      sx={{ height: 120 }}
      bg="background"
      justifyContent={["center", "space-between"]}
      alignItems="center"
    >
      <Link to="/" color="primary">
        <Image
          src={LogoImg}
          sx={{
            width: [150],
          }}
        ></Image>
      </Link>
      <Flex alignItems="center" justifyContent="space-between">
        <Text
          fontSize={[0, 1]}
          color="primary"
          display={["none", "block"]}
          mr={[4]}
          onClick={() => {
            setIsModalOpen(true)
          }}
        >
          <Flex
            justifyContent="center"
            sx={{ cursor: "pointer" }}
            alignItems="center"
          >
            Cos'è la Donation League?
          </Flex>
        </Text>
        <Text fontSize={[0, 1]} display={["none", "block"]}>
          <RebassLink sx={{ textDecoration: "none" }} href="#steps">
            <Flex justifyContent="center" alignItems="center">
              <FaInfoCircle style={{ marginRight: "5px" }} /> Come funziona?
            </Flex>
          </RebassLink>
        </Text>
        {/*<DonationButton
        withIcon={false}
        text="Come funziona?"
        onClick={() => {
          window.location.href = "#steps"
        }}
      />*/}
        <LayoutModal width={900} isOpen={isModalOpen} toggle={toggle}>
          <Flex justifyContent="center" sx={{ borderRadius: 8 }}>
            <SiteDescription />
          </Flex>
        </LayoutModal>
      </Flex>
    </Flex>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
