import React from "react"
import { Flex, Image, Heading, Box, Text, Link as RebassLink } from "rebass"
import LogoImg from "../../images/logo.png"
import GofundmeLogo from "../../images/gofundme.png"

const SiteDescription = () => {
  return (
    <Flex
      px={[5]}
      py={[4]}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
    >
      <Image
        src={LogoImg}
        sx={{
          width: [150],
        }}
      ></Image>
      <Flex mt={[3, 4]}>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Heading fontSize={[0, 1, 2]}>
            Donation League è una raccolta di fondi destinata ai reparti di
            malattie infettive di tutta Italia in prima linea contro il
            coronavirus, rappresentati dalla Società Italiana di Malattie
            infettive (SIMIT). I fondi raccolti andranno a promuovere le cure e
            alla ricerca di terapie innovative in tutti gli ospedali italiani in
            cui gli infettivologi operano. Referenti e garanti dell’utilizzo di
            quanto donato su tutto il territorio nazionale saranno il Professor
            Massimo Galli, Direttore del Dipartimento di Scienze Biomediche e
            Cliniche Luigi Sacco e Past President della SIMIT, il Dottor
            Marcello Tavio, primario di malattie infettive all’Ospedale di
            Ancona e Presidente di SIMIT e il Professor Claudio Mastroianni,
            Direttore delle Malattie Infettive alla Sapienza in Roma e vice
            presidente SIMIT.
          </Heading>
          <Box mt={[3, 4]}>
            <Heading textAlign="center">
              Il campionato è fermo, il tifo no!
            </Heading>
            <Text mt={[1, 2]}>
              I giocatori non possono scendere in campo ma lo fanno i loro
              tifosi, con il loro cuore. Donando e sostenendo la ricerca puoi
              far vincere alla tua squadra del cuore il campionato della
              solidarietà. Chi vincerà?
            </Text>
          </Box>
          <Box mt={[3, 4]}>
            <Heading textAlign="center">
              Insieme facciamo gol contro il coronavirus
            </Heading>
            <Text mt={[1, 2]}>
              A questo "campionato solidale" partecipano tutti i tifosi delle
              squadre di serie A. Il coronavirus non può cancellare l'entusiasmo
              delle tifoserie, la passione per la propria squadra del cuore. Il
              campionato continua sul "campo" delle donazioni.
            </Text>
            <Text mt={[1, 2]}>
              Nella piattaforma per le donazioni è inserito un "menu a tendina"
              con il nome delle tifoserie/squadre partecipanti (serie A)Per ogni
              donazione, oltre ai dati classici (donazione, nome e e-mail), ogni
              donatore indica anche la sua squadra del cuore.
            </Text>
            <Text mt={[1, 2]}>
              I tifosi dimostreranno il loro affetto per la propria squadra del
              cuore attraverso le donazioni a favore della lotta contro il
              coronavirus La classifica si compila attraverso le performance
              giornaliere delle donazioni dei tifosi.Avremo quindi queste 3
              classifiche:
              <ul style={{ padding: 0, listStyleType: "none" }}>
                <li>
                  <strong>1 - </strong> quella a punti (1 punto a donazione)
                </li>
                <li>
                  <strong>2 - </strong> quella con il totale delle cifre donate
                  da ogni tifoseria
                </li>
                <li>
                  <strong>3 - </strong> media singola donazione (per squadra)
                </li>
              </ul>
            </Text>
          </Box>
          <Box mt={[3, 4]}>
            <Text>
              Per la <strong>Donation League</strong> è stato creato un sito{" "}
              dove viene pubblicata la classifica donazioni delle tifoserie che
              si aggiorna ogni 5 minuti.
            </Text>
          </Box>
          <Flex
            mt={[3, 4]}
            width="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Image src={GofundmeLogo} height={60} width={[230, 80]} mr={[2]} />
            <Text fontSize={[0, 1]}>
              Le donazioni vengono raccolte da
              <strong> gofundme.com</strong>, il sito di raccolta fondi gratuito
              n.1 al mondo.
            </Text>
          </Flex>
          <Flex mt={[3, 4]} flexDirection="column">
            <Heading fontSize={[1, 2]} color="primary">
              Ecco il numero di tifosi delle venti squadre di Serie A
            </Heading>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              <li>Juventus - 8.725.000</li>
              <li>Inter - 3.975.000</li>
              <li>Milan - 3.868.000</li>
              <li>Napoli - 2.783.000</li>
              <li>Roma - 1.895.000</li>
              <li>Fiorentina - 673.000</li>
              <li>Lazio - 606.000</li>
              <li>Cagliari - 520.000</li>
              <li>Torino - 462.000</li>
              <li>Bologna - 328.000</li>
              <li>Sampdoria - 250.000</li>
              <li>Atalanta - 245.000</li>
              <li>Genoa - 200.000</li>
              <li>Udinese - 170.000</li>
              <li>Verona - 131.000</li>
              <li>SPAL - 121.000</li>
              <li>Parma - 100.000</li>
              <li>Lecce - 98.000</li>
              <li>Brescia - 91.000</li>
              <li>Sassuolo - 72.000</li>
            </ul>
            <Heading fontSize={(0, 1, 2)} color="primary">
              Per un totale di <strong>25.313.000 tifosi di Serie A</strong>
            </Heading>
            <Box fontSize={[0]} color="lightgray" mt={[2, 3]}>
              Iniziativa No-profit di Claudio Cecchetto in collaborazione con
              Rewave, ImpactOn
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default SiteDescription
